.ommig-container {
    padding: 100px 0;
    text-align: center;
    width: 710px;
    margin: 0 auto;
}

.ommig-container h2 {
    padding-bottom: 20px;
}

.ommig-content {
    display: flex;
    flex-direction: row;
}

.imgOfMe {
    padding-right: 30px;
    border-right: solid 1px #1b1b1b58;
}

.ommig-description {
    margin-left: 30px;
    margin-top: 10px;
    text-align: left;

}

.ommig-button {
    margin-top: 30px;
}

@media only screen and (max-width: 813px) {
    .ommig-container {
        padding: 70px 0;
        width: 100%;
    }

    .ommig-description {
        width: 70%;
        text-align: center;
        margin: 0;
    }

    .imgOfMe {
        padding-bottom: 30px;
        padding-right: 0;
        border-right: none;
        width: 250px;
        height: auto;

    }

    .ommig-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}