.kompentencer-container{
    padding: 70px 0;
    text-align: center;
}

.iconlink {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    text-align: center;
    max-width: 960px;
    margin: 0 auto;

}


@media only screen and (max-width: 813px) {
    .kompentencer-container{
        padding: 70px 0 0 0;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .iconlink {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        text-align: center;
        max-width: 960px;
        margin: 0 auto;
    }
    
}