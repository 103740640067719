* {
    margin: 0;
    box-sizing: border-box;
}

.title {
    font-size: 15px;
    font-weight: 200;
    /* color: #f1356d; */
}

.subtitle {
    font-size: 25px;
    font-weight: 400;
}
