.icon-card {
    padding-top: 10px;
}

.icon {
    height: 100px;
}

.icon-text {
    font-size: 15px;
    font-weight: 300;
    color: #6c757d;
}