.kontakt-container {
    padding-top: 70px;
    text-align: center;
    position: relative;
}

.kontakt-container::before {
    content: "";
    background-image: url(../assets/laptop2.jpg);
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: .3;
}

.kontakt-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-form {
    width: 700px;
}

.socialmedia {
    margin-top: 50px;
}

small {
    margin-top: 30px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 813px) {
    .contact-form {
        width: 500px;
    }
}