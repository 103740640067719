.intro-container {
    padding-top: 70px;
    text-align: center;
    position: relative;
    height: 100vh;
}

.intro-container::before {
    content: "";
    background-image: url(../assets/laptop.jpg);
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: .3;
}

.intro-text {
    margin-left: 150px;
    margin-top: 100px;
    max-width: 650px;
    font-weight: 300; 
    text-align: left;
}

.intro-p-one {
    font-size: 18px;
}

.intro-name {
    font-size: 40px;
}

.intro-p-two {
    font-size: 18px;
}

.intro-button {
    display: flex;
    gap: 20px;
    margin-left: 150px;
    margin-top: 30px;
}

.btn{
    width: 150px !important;
}

@media only screen and (max-width: 813px) {

    .intro-text {
        margin-left: 50px;
        width: 70%;
        top: 15%;
        font-weight: 300; 
    }

    .intro-button {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-left: 50px;
        max-width: 250px;
    }
        
}