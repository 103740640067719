.portfolio-container {
    padding: 70px 0;
    text-align: center;
}

.portfolio-container h2 {
    padding-bottom: 20px;
}

.card-portfolio {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 25px;
    justify-content: center;
}

.img-portfolio {
    height: 350px;
    width: auto;
    border-radius: 10px;
}

.portfolio-img-card {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
}

/* .overlay-blur {
    backdrop-filter: blur(5px);
} */

.overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s;
}

.overlay:hover {
    opacity: 1;
}

.overlay:hover > * {
    transform: translateY(0);
}

.img-title {
    width: 300px;
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 10px;
}

@media only screen and (max-width: 813px) {
    .card-portfolio {
        display: flex;
        flex-direction: column;
    }    

    .portfolio-container {
        width: 400px;
    }

    .img-portfolio {
        width: 400px;
        height: auto;
    }
}